import { setTokenInClient } from '../../apis';
import { setUserMeta, setToken, setUserId } from './setStorageObject';

function getErrorMessageFromResponse({ error = {} } = {}) {
	let message = '';
	if (error?.response?.data?.error?.details?.length > 0) {
		[{ message } = {}] = error?.response?.data?.error?.details || [];
	}

	message = message || error?.response?.data?.error?.message;
	message = message || error?.response?.error?.message;
	message = message || error.message;
	return message;
}

async function initAuthToken({ token }) {
	await setToken({ token });
	await setTokenInClient({ token });
}

async function removeData() {
	await setUserMeta(null);
	setUserId(null);
	setUserIdContext(null);
	setLoggedIn(false);
}

async function initUserAfterAuth({
	userMeta,
	setUserData,
	setLoggedIn,
	userId,
	setUserId: setUserIdContext,
}) {
	try {
		if (userMeta) {
			await setUserMeta({ userMeta });
		}

		if (userId) {
			await setUserId({ userId });
			if (setUserIdContext) {
				setUserIdContext(userId);
			}
		}

		if (setUserData) {
			setUserData(userMeta);
		}

		if (setLoggedIn && (userId || userMeta)) {
			setLoggedIn(true);
		}
	} catch (error) {
		console.error(error);
	}
}

export { getErrorMessageFromResponse, initUserAfterAuth, initAuthToken, removeData };
