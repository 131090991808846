import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';

const ResetPassword = React.lazy(()=> import('../pages/User/Auth/resetpassword'))
const LandingPage = React.lazy(() => import('../pages/Landing'));
const Signup = React.lazy(() => import('../pages/User/Auth/signup'));
const Login = React.lazy(() => import('../pages/User/Auth/login'));
const OtpVerify = React.lazy(() => import('../pages/User/Auth/verify-otp'));
const TermsAndConditions = React.lazy(() => import('../pages/TermsAndConditions'));
const Disclaimer = React.lazy(() => import('../pages/Disclaimer'));
const AppRoutes = React.lazy(() => import('./AppRoutes'));
const UserList = React.lazy(() => import('../pages/Admin/UserList'));
const JoinWaitlist = React.lazy(() => import('../pages/User/Auth/join-waitlist'));
const AssessmentTest = React.lazy(() => import('../pages/User/Auth/assessment-test'));
const AssessmentReport = React.lazy(() => import('../pages/User/Auth/assessment-report'));
const WaitlistUsers = React.lazy(()=> import('../pages/Admin/Waitlist'));
const AssessmentGeneral = React.lazy(()=> import('../pages/User/Auth/assessment-general'))
const AssessmentGenReport = React.lazy(()=> import('../pages/User/Auth/assessment-gen-report'));

const Routes = React.memo(({ loggedIn }) => {
	const location = useLocation();
	const history = useHistory();
	const { pathname } = location || {};
	const loggedOutRoutes = useMemo(
		() => ['/signup', '/login', '/otp-verify','/privacy-policy','/resetpassword','/disclaimer','/user-list','/', 
		'/join-waitlist','/assessment-test','/assessment-report','/waitlist-users','/assessment-general','/assessment-gen-report'],
		[],
	);
	const publicRoutes = useMemo(() => ['/help', '/resetpassword','/user-list', '/waitlist-users'], []);
	if (
		!loggedIn &&
		!loggedOutRoutes.includes(pathname) &&
		!publicRoutes.includes(pathname)
	) {
		const redirectUrl = global.location.pathname + global.location.search;
		history.push(`/login?redirect=${redirectUrl}`);
		return null;
	}

	if (
		loggedIn &&
		loggedOutRoutes.includes(pathname) &&
		!publicRoutes.includes(pathname)
	) {
		history.push('/app');
		return null;
	}

	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route exact path="/" component={LandingPage} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/signup" component={Signup} />
				<Route exact path="/resetpassword" component={ResetPassword} />
				<Route exact path="/otp-verify" component={OtpVerify} />
				<Route path="/app" component={AppRoutes} />
				<Route path="/privacy-policy" component={TermsAndConditions}/>
				<Route path="/disclaimer" component={Disclaimer}/>
				<Route path="/user-list" component={UserList}/>
				<Route path="/join-waitlist" component={JoinWaitlist}/>
				<Route path="/assessment-test" component={AssessmentTest}/>
				<Route path="/assessment-report" component={AssessmentReport}/>
				<Route path="/assessment-general" component={AssessmentGeneral}/>
				<Route path="/assessment-gen-report" component={AssessmentGenReport}/>
				<Route path="/waitlist-users" component={WaitlistUsers}/>
			</Switch>
		</Suspense>
	);
});

Routes.propTypes = {
	loggedIn: PropTypes.bool.isRequired,
};

export default Routes;
