import axiosClient from './axiosClient';

function sendPasswordMail({ data } = {}) {
  return axiosClient.post("/sendPasswordMail", data);
}

function sendFeedbackMail({ data } = {}) {
  return axiosClient.post("/sendFeedbackMail", data);
}

function sendSupportEmail({ data } = {}) {
  return axiosClient.post("/sendSupportMail", data);
}

function sendGetInTouchEmail({ data } = {}) {
  return axiosClient.post("/sendGetInTouchMail", data);
}

function signupUser({ data } = {}) {
  return axiosClient.post("/userSignup", data);
}

function isTokenValid ({ data } = {}){
  return axiosClient.post("/isTokenValid", data);
}

function userLoginWithEmail({ data } = {}) {
  return axiosClient.post("/userLoginWithEmail", data);
}

function getUserMetaData({ userId } = {}) {
  if (!userId) {
    return {};
  }

  return axiosClient.get("/getUserMetaData", { params: { userid: userId } });
}

function userLoginWithPhone({ data } = {}) {
  return axiosClient.post("/userLoginWithPhone", data);
}

function generateOtp({ data } = {}) {
  console.log('data: ', data);
  return axiosClient.post("/generateOTP",  data );
}

function generateOtpForEmail({ data } = {}) {
  return axiosClient.post("/generateOtpforEmail", data );
}

function setTokenInClient({ token } = {}) {
  axiosClient.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : undefined;
}

function getStudentDash({ userid } = {}) {
  axiosClient.get("/getStudentDash", { params: { userid } });
}

function updateUserProfile(data) {
  return axiosClient.put(
    `/updatestudentProfile/${data.student_details.id}`,
    data
  );
}

function userLoginWithUserId({ data } = {}) {
  return axiosClient.post("/userLoginWithUserId", data);
}

// function verifyEmail({ email, otp, userId } = {}) {
// 	return axiosClient.get('/verifyEmailOtp', { params: { userid: userId, otp: otp, email: email } });
// }

function verifyEmail({ email, otp, userId } = {}) {
  return axiosClient.post("/verifyEmailOtp", {
    email: email,
    otp: otp,
  });
}

function resetPassword({ data } = {}) {
  return axiosClient.post("/resetPassword", data);
}

function uploadAvatar({ data } = {}) {
  return axiosClient.post("/uploadAvtar", data);
}

function getUserExpData({ email } = {}) {
  return axiosClient.get("/getUserExpData", { params: { email } });
}

function deleteUserExpData({ userId } = {}) {
  return axiosClient.get("/deleteUserExpData", { params: { userId } });
}

function joinWaitlist({ data } = {}) {
  return axiosClient.post("/joinWaitlist", data);
}

function getAllAssessmentLink() {
  return axiosClient.get("/getAllAssessmentLink");
}

function getAssessmentReport({ assessmentId, userid } = {}) {
  return axiosClient.get("/getExperimentXls", {
    params: { assessmentId: assessmentId, userid: userid },
  });
}

function getAllWaitlistUsers({ page } = {}) {
  return axiosClient.get("/getWaitlist", { params: { page } });
}

function deleteWaitlistUser({ emailId } = {}) {
  return axiosClient.get("/deleteWaitlistUser", { params: { emailId } });
}

function isAssessmentCompleted(id, userId) {
  return axiosClient.get("/isAssessmentCompleted", {params: {id, userId}});
}

const apis = {
  signupUser,
  userLoginWithEmail,
  getUserMetaData,
  userLoginWithPhone,
  generateOtp,
  generateOtpForEmail,
  setTokenInClient,
  getStudentDash,
  updateUserProfile,
  userLoginWithUserId,
  verifyEmail,
  sendPasswordMail,
  sendSupportEmail,
  sendFeedbackMail,
  resetPassword,
  uploadAvatar,
  getUserExpData,
  deleteUserExpData,
  joinWaitlist,
  getAllAssessmentLink,
  getAssessmentReport,
  getAllWaitlistUsers,
  deleteWaitlistUser,
  isAssessmentCompleted,
  isTokenValid
};

export {
  deleteUserExpData,
  deleteWaitlistUser,
  generateOtp,
  generateOtpForEmail,
  getAllAssessmentLink,
  getAllWaitlistUsers,
  getAssessmentReport,
  getStudentDash,
  getUserExpData,
  getUserMetaData,
  isAssessmentCompleted,
  isTokenValid,
  joinWaitlist,
  resetPassword,
  sendFeedbackMail,
  sendGetInTouchEmail,
  sendPasswordMail,
  sendSupportEmail,
  setTokenInClient,
  signupUser,
  updateUserProfile,
  uploadAvatar,
  userLoginWithEmail,
  userLoginWithPhone,
  userLoginWithUserId,
  verifyEmail,
};

export default apis;
